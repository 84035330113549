import { ref } from 'vue'
import { DateTime } from 'luxon'

export default function useLuxon() {
  const error = ref(null)

  const dateFromISO = (dateString) => {
    if (!dateString) return
    error.value = null
    try {
      const dt = DateTime.fromISO(dateString)
      return dt.toLocaleString(DateTime.DATE_FULL)
    } catch (err) {
      console.error(err.message)
      error.value = err.message
    }
  }

  const dateFromSeconds = (seconds) => {
    if (!seconds) return
    error.value = null
    try {
      const dt = DateTime.fromSeconds(seconds)
      return dt.toLocaleString(DateTime.DATE_FULL)
    } catch (err) {
      console.error(err.message)
      error.value = err.message
    }
  }

  const fromSeconds = (seconds) => {
    if (!seconds) return
    error.value = null
    try {
      const dt = DateTime.fromSeconds(seconds)
      return dt.toLocaleString(DateTime.DATETIME_MED)
    } catch (err) {
      console.error(err.message)
      error.value = err.message
    }
  }

  return { error, dateFromISO, dateFromSeconds, fromSeconds }
}

// https://moment.github.io/luxon/docs/manual/formatting.html#presets
